import create from 'zustand'

// A simple boolean store to for when an updated service worker is available
const UpdateStore = create(set => {
    return {
        updateAvailable: false,
        setUpdateAvailable: updateAvailable => {
            set({ updateAvailable })
        },
    }
})

export const useUpdateAvailable = () => UpdateStore(state => state.updateAvailable)
export const useSetUpdateAvailable = () => UpdateStore(state => state.setUpdateAvailable)

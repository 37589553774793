import { useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import mixpanel from 'mixpanel-browser'

export const useSendTrackEvent = () => {
    const { user } = useAuth0()

    const sendTrackEvent = useCallback(
        (eventName: string, event: Record<string, unknown>) => {
            // Have to explicitly handle the forecast object since it comes through as a string and
            // that's the only way to pass it through the dataset in the Summary component.
            let eventObject = event.forecast
            if (typeof event.forecast === 'string') {
                eventObject = JSON.parse(event.forecast)
            }

            mixpanel.track(eventName, {
                ...event,
                forecast: eventObject,
                user_id: user?.sub,
            })
        },
        [user?.sub]
    )

    return sendTrackEvent
}

import { FR, EN } from '@avalanche-canada/constants/locales'

import EnglishMessages from './locales/compiled/en-CA.json'
import FrenchMessages from './locales/compiled/fr-CA.json'

import { server } from 'env'

export default new Map([
    [FR, FrenchMessages],
    [EN, EnglishMessages],
])

export const LOCALE_TEXTS = new Map([
    [EN, 'English'],
    [FR, 'Français'],
])

// As this is a PWA, let's get the locale from window.navigator.languages so that it will change when a users device language settings change.
export function getFromNavigator(defaultLocale = EN) {
    try {
        const [language] = window.navigator.languages

        return language.substring(0, 2).toLowerCase() === 'fr' ? FR : defaultLocale
    } catch {
        return defaultLocale
    }
}

export function setDocumentElementLang(locale) {
    if (!server) {
        window.document.documentElement.lang = locale
    }
}

/* eslint-disable no-console */

import * as Sentry from '@sentry/browser'

export function captureException(error, context) {
    Sentry.withScope(scope => {
        scope.setExtras(context)
        Sentry.captureException(error)

        console.error(error, context)
    })
}

export function setUserContext({ sub, email, name }) {
    Sentry.withScope(scope => {
        scope.setUser({
            id: sub,
            email,
            username: name,
        })
    })
}

export function captureMessage(message) {
    Sentry.captureMessage(message)
}

import create from 'zustand'
import { persist } from 'zustand/middleware'

import { getFromNavigator, setDocumentElementLang } from 'services/intl/messages'

const initialLocale = getFromNavigator()

const SettingsStore = create(
    persist(
        (set, get) => {
            setDocumentElementLang(initialLocale)
            return {
                locale: initialLocale,
                setLocale: locale => {
                    setDocumentElementLang(locale)
                    set({ locale })
                },
                colorBlindMode: false,
                setColorBlindMode: colorBlindMode => {
                    set({ colorBlindMode })
                },
                appOpenedCount: 0,
                setAppOpenedCount: appOpenedCount => {
                    set({ appOpenedCount })
                },
            }
        },
        {
            name: 'settings', // Will persist to localStorage under the key "settings"
        }
    )
)

export const useLocale = () => SettingsStore(state => state.locale)
export const useSetLocale = () => SettingsStore(state => state.setLocale)
export const useColorBlindMode = () => SettingsStore(state => state.colorBlindMode)
export const useSetColorBlindMode = () => SettingsStore(state => state.setColorBlindMode)
export const useAppOpenedCount = () => SettingsStore(state => state.appOpenedCount)
export const useSetAppOpenedCount = () => SettingsStore(state => state.setAppOpenedCount)

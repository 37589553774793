import create from 'zustand'

type MapEvent = {
    action: string
    zoom: number
    center: {
        lat: number
        lng: number
    }
    eventType: string
}

type State = {
    mapHistory: MapEvent[]
    forecastHistory: any[]
    forecastId: string | null
}

type Action = {
    setMapHistory: (mapHistory: State['mapHistory']) => void
    setForecastHistory: (forecastHistory: State['forecastHistory']) => void
    setForecastId: (forecastId: State['forecastId']) => void
    pushMapHistory: (mapHistoryItem: MapEvent) => void
    pushForecastHistory: (forecastHistoryItem: any) => void
}

export const MixpanelHistoryStore = create<State & Action>()(set => {
    return {
        mapHistory: [],
        forecastHistory: [],
        forecastId: null,
        setMapHistory(mapHistory: State['mapHistory']) {
            set({ mapHistory })
        },
        pushMapHistory(mapHistoryItem: MapEvent) {
            set(state => ({ mapHistory: [...state.mapHistory, mapHistoryItem] }))
        },
        setForecastHistory(forecastHistory: State['forecastHistory']) {
            set({ forecastHistory })
        },
        setForecastId(forecastId: State['forecastId']) {
            set({ forecastId })
        },
        pushForecastHistory(forecastHistoryItem: any) {
            set(state => ({ forecastHistory: [...state.forecastHistory, forecastHistoryItem] }))
        },
    }
})

export const useMapHistory = () => MixpanelHistoryStore(state => state.mapHistory)
export const useSetMapHistory = () => MixpanelHistoryStore(state => state.setMapHistory)
export const usePushMapHistory = () => MixpanelHistoryStore(state => state.pushMapHistory)
export const useForecastHistory = () => MixpanelHistoryStore(state => state.forecastHistory)
export const useSetForecastHistory = () => MixpanelHistoryStore(state => state.setForecastHistory)
export const usePushForecastHistory = () => MixpanelHistoryStore(state => state.pushForecastHistory)
export const useForecastId = () => MixpanelHistoryStore(state => state.forecastId)
export const useSetForecastId = () => MixpanelHistoryStore(state => state.setForecastId)
